/* BuyThisDomain.css */
.buy-this-domain {
    text-align: center;
    margin-top: 50px;
  }
  
  .buy-this-domain h1 {
    font-size: 3rem;
    color: #333;
  }
  
  .buy-this-domain p {
    font-size: 1.5rem;
    color: #555;
  }
  
  .buy-this-domain-link {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
  }
  
  .buy-this-domain-link:hover {
    background-color: #0056b3;
  }
  